import React, {useState, useEffect, useRef, useMemo} from 'react'
import { useRouter } from 'next/router';

import { HomepageDropdown } from "./dropdown"

//redux
import { useAppSelector } from '@/src/hooks/useSelector';

import useOutsideClick2 from '@/src/hooks/useOutsideClick2'

import { displayGenre, displayPrefecture } from '@/src/helpers/displayDropdownChoiceValue';
import { GENRE_CHOICES, PREFECTURE_CHOICES } from '@/src/constants/dropdownChoices';


export const HomepageSearchBar = () => {
    const router = useRouter();
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    const searhBarRef = useRef(null)
    
    type Active = '' | 'genre' | 'location'
    const [active, setActive] = useState<Active>('')
    const setActiveHandler = (value: Active) => {
        if (active==value) {
            setActive('')
        } else {
            setActive(value)
        }
    }

    // click outside closes active
    useOutsideClick2(searhBarRef, () => setActive(''))

    //selected value
    const initialSelected = {genre:'', location:''}
    const [selected, setSelected] = useState(initialSelected)



    //choices
    const genreChoices = useMemo(() => (GENRE_CHOICES.map((g:any) => {
        return {label:g[l], value:g['en']}
    })),[l])

    const prefectureChoices = useMemo(() => (PREFECTURE_CHOICES.map((p:any) => {
        return {label:p[l], value:p['en']}
    })),[l])



    /**
     * SUBMIT HANDLER
    */ 
    const submitHandler = () => {
        router.push({
            pathname: '/search-lessons',
            query: selected
        })
    }



    /**
     * TEXT TRANSLATIONS
     */
    const text:any = {
        genre: {en:'Genre', jp:'ジャンル'},
        choose_genre: {en:'Choose Genre', jp:'ジャンル'},
        location: {en:'Location', jp:'場所を選ぶ'},
        choose_location: {en:'Choose Location', jp:'場所を選ぶ'},
        search_lesson_button: {en:'Search Dance lessons', jp:'ダンスレッスン検索'},
    }
    //{text['genre'][l]}

    return (
        <div 
            ref={searhBarRef} 
            className='
                flex flex-col gap-3 md:flex-row md:gap-1 border shadow-md 
                w-full justify-start items-center md:justify-center rounded-lg p-2 '
        >
            <div className='w-full'>
                <HomepageDropdown 
                    upperText={text['genre'][l]}
                    placeholder={text['choose_genre'][l]}
                    options={genreChoices}
                    isActive={active=='genre'}
                    setActive = {() => setActiveHandler('genre')}
                    selected={displayGenre(selected.genre, l)}
                    setSelected={(val) => setSelected((prev) => ({...prev, genre:val}))}
                />
            </div>
            <div className='w-full'>
                <HomepageDropdown 
                    upperText={text['location'][l]}
                    placeholder={text['choose_location'][l]}
                    options={prefectureChoices}
                    isActive = {active=='location'}
                    setActive = {() => setActiveHandler('location')}
                    selected={displayPrefecture(selected.location,l)}
                    setSelected={(val) => setSelected((prev) => ({...prev, location:val}))}
                />
            </div>

            <div className='w-full h-[60px] flex justify-center items-center p-1 '>
                <button
                    onClick={submitHandler}
                    className='w-full h-full rounded-lg
                        bg-gray-700 hover:bg-gray-800 text-white'
                    >{text['search_lesson_button'][l]}
                </button>
            </div>
        </div>
    )
}