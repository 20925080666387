import React, {useState, useEffect} from 'react'


import { IoMdMusicalNotes } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";



interface HomepageDropdownProps {
    upperText: string;
    placeholder: string;
    options: {label:string, value:string}[];

    isActive: boolean;
    setActive: () => void;
    selected: string;
    setSelected: (val:string) => void;
}


export const HomepageDropdown = ({
    upperText, placeholder,
    options,
    isActive, setActive,
    selected, setSelected
}:HomepageDropdownProps) => {

    //const [isActive, setIsActive] = useState(false)

    const selectHandler = (e:React.SyntheticEvent, value:string) => {
        e.preventDefault()
        setSelected(value)
        setActive()
    }

    return (
        <div className="w-full h-[60px] relative">
            <button
                className="w-full bg-white hover:bg-gray-100 py-2 
                    rounded-lg cursor-pointer flex flex-row items-center 
                    gap-7 sm:gap-10 md:gap-3
                    px-10
                    justify-start  md:justify-start
                    "
                role="combobox"
                aria-labelledby="select button"
                aria-haspopup="listbox"
                aria-expanded="false"
                aria-controls={`${upperText}-dropdown`}
                onClick={setActive}
                style={{zIndex:0}}
            >
                <IoMdMusicalNotes size={30} className=''/>
                <div className='flex flex-col'>
                    <div className='flex flex-row gap-2 items-center'>
                        <p className='text-sm text-gray-500 font-semibold'>{upperText}</p>
                        <MdOutlineKeyboardArrowDown size={20} className={`
                            text-orange-600 transition duration-600
                            ${isActive ? 'rotate-180' : ''}
                        `}/>
                    </div>
                    <p className='font-semibold '>{selected ? selected : placeholder}</p>
                </div>
            </button>
            <ul 
                role="listbox" 
                id={`${upperText}-dropdown`}
                style={{zIndex:1}}
                className={`
                    absolute  w-full shadow-md bg-white border rounded-md p-2.5 mt-2.5 max-h-[200px] overflow-y-auto 
                    transition duration-600
                    ${isActive ? 'opacity-100' : '-translate-y-1/2 opacity-0 pointer-events-none'}
                `}
            >
                {   options.map((o, index) => {
                    return (
                        <li 
                            role="option"
                            aria-selected={o.value =='true'}
                            className='relative cursor-pointer flex gap-1 items-center hover:bg-gray-100'
                            key={index}
                            onClick={(e) => selectHandler(e, o.value)}
                        >
                            <input 
                                type="radio" 
                                id={o.value}
                                value={o.value}
                                className='hidden'
                            />
                            <label 
                                htmlFor={o.value}
                                className='w-full p-2 cursor-pointer'
                                >{o.label}
                            </label>
                        </li>
                    )
                })
                }
            </ul>
        </div>
    )
}