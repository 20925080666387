import React, {useState, useEffect} from 'react'
import { useRouter } from 'next/router';

//react-icons
import { BsCalendar } from "react-icons/bs";
import { IoMdPin } from "react-icons/io";

import { RiBookmarkLine, RiBookmarkFill } from "react-icons/ri";


import { ProfilePictureIconInstructor } from '@/src/atoms/profilePictureIconInstructor';
import { CardTag } from "@/src/atoms/tag/cardTag";
import { CoverPhotoLesson } from '@/src/atoms/coverPhotoLesson';


import { displayDate1 } from '@/src/helpers/datetime_locale';
import { calcEndTime } from '@/src/helpers/date-time/time';

import { LessonTypes, LessonPublishedTypes } from "@/src/types/lesson";

//dropdown choices
import { displayGenre, displayTargetAudience, displayPrefecture } from '@/src/helpers/displayDropdownChoiceValue'

//redux to get user & bookmark slice
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { addBookmarkLesson, removeBookmarkLesson } from '@/store/slices/bookmarkSlice';


interface LessonListingCardProps {
  lesson: LessonPublishedTypes;
  className?: string;
  onClick:() => void;
}

export const LessonListingCard = ({
  lesson, 
  className='',
  onClick
}:LessonListingCardProps) => {
  const router = useRouter();
  //language translation settings
  const l = useAppSelector(state => state.settings).data.language

  //redux
  //const userData = useAppSelector(state => state.user).data
  const dispatch = useAppDispatch();
  const bookmarkData = useAppSelector(state => state.bookmark).data
  const {lesson:bookmarkLessons} = bookmarkData

  //deconstructing lesson & lesson Class
  const {lessonId, coverPhoto} = lesson


  const [lessonClass, setLessonClass] = useState<{[key:string]:any}>({
    date:null, time:null, start_datetime: null, duration:null,
    bookingMax:null, bookingCurrent:null,
    is_location:null, locationName:null, prefecture:null
  })
  const {
    date, time, start_datetime, duration,
    bookingMax, bookingCurrent,
    is_location, locationName, prefecture,
    instructor_photo
  } = lessonClass


  const {price} = lesson

  useEffect(() => {
    if (lesson['lessonClass']) {
      setLessonClass(lesson.lessonClass)
    }
  },[lesson])
  
  const dateDisplay = displayDate1(new Date(start_datetime), l)
  const endTime = calcEndTime(time, duration)
  
  //bookmarked state
  const [bookmarked, setBookmarked] = useState(false)
  useEffect(() => {
    if (bookmarkLessons.includes(lessonId)) {
      setBookmarked(true)
    }
  },[bookmarkLessons, lessonId])

  //bookmarking lesson 
  const bookmarkLessonHandler = (event:React.SyntheticEvent) => {
    event.stopPropagation();

    //check if in boomkarkllist.
    if (bookmarkLessons.includes(lessonId)) {
      // remove from list
      //console.log("removing lesson")
      dispatch(removeBookmarkLesson(lessonId))
      setBookmarked(false)
    } else {
      // add to list
      //console.log("addig lesson")
      dispatch(addBookmarkLesson(lessonId))
      setBookmarked(true)
    }
  }

  const bookingAvailable = bookingMax ? bookingMax - bookingCurrent : ''


  /**
   * TRANSLATION TEXT
   */
  const text:any = {
    spots_left: {
      en: bookingMax ? `${bookingMax - bookingCurrent} spots left` : '', 
      jp: bookingMax ? `残り${bookingMax - bookingCurrent}` : '', 
    },
    price:{
      en: price ? `¥${price}` : 'free',
      jp: price ? `¥${price}` : '無料',
    }
  }

  //{text['price'][l]}




  return (
    <div 
      className={` 
        w-fit sm:w-full flex flex-col sm:flex-row gap-4 rounded-3xl p-2
        border cursor-pointer
        hover:shadow-sm hover:bg-gray-50
        ${className}
      `}
      onClick={onClick}
    >
      {/** COVER PICTURE */}
      <div className={`
        h-[160px]  w-fit rounded-md resize-none 
        aspect-video
      `}>
        <CoverPhotoLesson 
          height='w-full sm:w-fit sm:h-full'
          className='rounded-xl'
          textSize=''
          image={coverPhoto}
        />
      </div>

      <div className=" flex flex-col text-left gap-2">
          <div className="flex flex-col gap-1">
            <h3
              className="text-[20px] font-bold leading-0 truncate"
              >{lesson.title}
            </h3>
            <div className="flex flex-row flex-wrap text-2xl gap-x-2 gap-y-1">
              <CardTag text={displayGenre(lesson.genre, l)} />
              <CardTag text={displayTargetAudience(lesson.targetAudience, l)} />
            </div>

            {/** INSTRUCTOR */}
            <div className="flex items-center text-base font-bold gap-2">
              <ProfilePictureIconInstructor 
                height='h-[30px]'
                textSize='text-md'
                image={instructor_photo}
              />
              <p className='text-[14px] font-semibold text-gray-500'>{lesson.instructorName}</p>
            </div>
          </div>

          <div className="flex flex-col gap-1 ml-1">
            <div className='flex flex-row gap-2 items-center'>
              <BsCalendar size={20} className='text-gray-700'/>
              <p className="text-[14px] font-semibold text-gray-700">
                {dateDisplay} {time} - {endTime}
              </p>
            </div>

            <div className='flex flex-row gap-2 items-center'>
              <IoMdPin size={20} className='text-gray-700'/>
              { is_location ? (
                <p className="text-[14px] font-semibold text-gray-700">{locationName}, {displayPrefecture(prefecture, l)}</p>
              ) : (
                <p className="text-[14px] font-semibold text-gray-700">{displayPrefecture(prefecture, l)}</p>
              )}
            </div>
            
          </div>
      </div>

      <div className="sm:ml-auto pl-2 sm:pl-0 mr-2 flex flex-row sm:flex-col sm:text-right items-center sm:items-end gap-2">
        <p className="text-[16px] font-semibold text-gray-700 sm:ml-auto">
          {text['price'][l]}
        </p>
        <p className='font-semibold text-sm'>
          {text['spots_left'][l]}
        </p>

        <div className="mt-auto flex flex-row gap-2 justify-end">
          <div 
            className='p-2 rounded-full hover:bg-gray-100'
            onClick={(event) => bookmarkLessonHandler(event)}
          >
            {  bookmarked ? (
              <RiBookmarkFill size={20} className='text-red-600'/>
            ) : (
              <RiBookmarkLine size={20} />
            )}
          </div>
        </div>

      </div>
    </div>
  )
}


